import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import InitLocale from './locales/_core/initLocale';
import { RecoilRoot } from 'recoil';
import App from './router/App';
import Parse from 'parse/dist/parse.min';
import { PARSE_APP_ID, PARSE_JS_KEY, PARSE_URL } from './config/env';
import { registerSW } from 'virtual:pwa-register';

// Sentry.init({
//     dsn: 'https://1446c4ed804080187e380266efb110f7@o4506892534743040.ingest.us.sentry.io/4506892539002880',
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /https:\/\/berriesflow\.fr\/parse/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Register PWA
registerSW({});

// Init Parse Server
Parse.initialize(PARSE_APP_ID, PARSE_JS_KEY);
Parse.serverURL = PARSE_URL;

// Init React
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <InitLocale>
                <App />
            </InitLocale>
        </RecoilRoot>
    </React.StrictMode>,
);
