import {selector} from "recoil";
import {_authProfile} from "./_authProfile";

export interface AuthSateInterface {
    init: boolean,
    isLogged: boolean,
    isRoot: boolean,
    isAdministrator: boolean,
    isModerator: boolean,
    isUser: boolean,
}

export const _authState = selector<AuthSateInterface>({
    key: 'authState',
    get: ({get}) => {

        const profile = get(_authProfile);
        const role: string =  profile?.get("berriesflow")?.role || "";

        return {
            init: profile !== null,
            isLogged: !!profile,
            isRoot: role === "Root",
            isAdministrator: ["Administrator"].includes(role),
            isModerator: ["Administrator", "Moderator"].includes(role),
            isUser: ["Administrator", "Moderator", "User"].includes(role)
        }

    }
});