import {atom, AtomEffect} from "recoil";
import Parse from "parse/dist/parse.min";


const loadProfile: AtomEffect<any> = ({setSelf}) => {

    const localProfile = Parse.User.current();
    if(!!localProfile) {
        Parse.User.current()?.fetchWithInclude("entreprise").then(
            user => {
                setSelf(user)
            },
            error => {
                setSelf(localProfile);
                console.log(error);
            }
        )
    }else {
        setSelf(undefined);
    }

};


export const _authProfile = atom<Parse.User | undefined | null>({
    key: "authProfile",
    default: null,              // null => not init / (Parse.User | undefined) => res query
    effects: [loadProfile]
})