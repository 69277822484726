
// Text React-Intl
export const locales: {[locale: string]: () => Promise<any>} = {
    fr: () => import("../fr/fr")
};


// Dayjs Locale
export const localesDayjs: {[locale: string]: () => Promise<any>} = {
    fr: () => import("dayjs/locale/fr"),
    en: () => import("dayjs/locale/en")
}