import React, {lazy, Suspense, useMemo} from "react";
import {useRecoilValue} from "recoil";
import {_authState} from "../store/auth/_authState";
import {SplashScreen} from "@berriesflow/core";


// import Routers
const RouterNoLogin = lazy(() => import("./routerNolLogin"));
const ProducerRouter = lazy(() => import("./producerRouter"));


export default function App(){

    const {init, isLogged} = useRecoilValue(_authState);

    const myRouter = useMemo(() => {
        if(isLogged) return <ProducerRouter/>
        return <RouterNoLogin/>
    }, [isLogged])


    return(
        <>
            {
                init ?
                    <Suspense fallback={<SplashScreen/>}>
                        { myRouter }
                    </Suspense>
                :
                    <SplashScreen/>
            }
        </>
    )

}
