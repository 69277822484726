export const PARSE_URL = import.meta.env.DEV
    ? // @ts-ignore
      import.meta.env.VITE_PARSE_URL || 'http://localhost:8080/parse'
    : 'https://www.berriesflow.fr/parse';

export const PARSE_APP_ID = process.env.NODE_ENV === 'development' ? 'berriesflow' : 'berriesflow';

export const PARSE_JS_KEY = import.meta.env.DEV
    ? 'MEbXqvc5FMUxhsTL3A42PVDdWXEyK38L'
    : 'wuKiURoR3c3VqMGh20KmUFcR2r1GcLh7';

export const PARSE_PDF_URL = import.meta.env.DEV
    ? // @ts-ignore
      import.meta.env.VITE_PARSE_PDF || 'http://localhost:8081/api/pdf/functions'
    : 'https://www.berriesflow.fr/api/pdf/functions';

export const PARSE_PDF_HEADERS = {
    'X-Parse-Application-Id': PARSE_APP_ID,
    'X-Parse-REST-API-Key': PARSE_JS_KEY,
    'Content-Type': 'application/json',
};
