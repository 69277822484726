import {atom} from "recoil";
import {locales} from "./locales";

// --- Verifications ---
const list_languages = Object.keys(locales);

export const localeAvailable = (newLocale: string) => {
    return list_languages.includes(newLocale) ? newLocale : list_languages[0]
}


// --- Effect ---
const storeToLocalStorage = (key: string) => ({setSelf, onSet}: any) => {
    // --- Init ---
    const actualLocale = localeAvailable(localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0]);
    setSelf(actualLocale);
    localStorage.setItem(key, localeAvailable(actualLocale));

    // --- On Set verification ---
    onSet((newValue: string) => {
        localStorage.setItem(key, localeAvailable(newValue));
    });
};

// --- Atom ---
export const _storeLocale = atom<string | undefined>({
    key: "storeLocale",
    default: undefined,
    effects: [
        storeToLocalStorage("locale")
    ]
})