import React, {ReactNode, useEffect, useState} from "react";
import dayjs from "dayjs";
import {locales, localesDayjs} from "./locales";
import {IntlProvider} from "react-intl";
import {useRecoilValue} from "recoil";
import {_storeLocale} from "./_storeLocale";

//import dayjs plugins
import localizedFormat from "dayjs/plugin/localizedFormat"
import {SplashScreen} from "@berriesflow/core";

// Init
dayjs.extend(localizedFormat);


interface Props {
    children: ReactNode
}


export default function InitLocale({children}:Props){

    const locale = useRecoilValue(_storeLocale);
    const [text, setText] = useState<any>(null);


    // --- Update Text ---
    useEffect(() => {

        if(locale){
            // console.log("Locale => ", locale);

            // Text
            locales[locale]().then((data: any) => {
                setText(data["default"]);
                // console.log(data["default"]);
            });

            // Date
            localesDayjs[locale]().then(() => {
                dayjs.locale(locale);
            })
        }

    }, [locale]);


    return(
        <>
            {
                (text !== null && !!locale) ?
                    <IntlProvider locale={locale} messages={text}>
                        {children}
                    </IntlProvider>
                :
                    <SplashScreen/>
            }
        </>
    )

}